<template>
  <v-form v-model="formValid" ref="invoiceToPayForm">
    <StandardDialog
      v-model="supplierDataErrorDialog"
      :dialog-height="null"
      dialog-width="400px"
      title="Dati Azienda Mancanti"
      @close="supplierDataErrorDialog = false"
    >
    <v-alert 
      border="left"
      class="py-2 my-3"
      color="error"
    >
     {{supplierDataErrorMessage}}
    </v-alert> 
    </StandardDialog>
    <v-row v-if="status === 'paid'">
      <div class="d-flex justify-center align-center my-2">
        <div class="d-flex justify-center align-center my-2">
          <AnimatedCheck class="mx-4" v-model="invoicePaid"></AnimatedCheck>
          <div class="text-h6" style="width: 500px; max-width: 90vw">
            {{ invoicePaidMessage }}
          </div>
        </div>
      </div>
    </v-row>
    <v-subheader class="font-italic font-weight-light py-1"
      >Generale</v-subheader
    >
    <v-row>
      <v-col
        cols="6"
        sm="4"
        md="2"
        lg="2"
        xl="2"
      >
        Codice Interno Fattura
        <v-text-field
          v-model="internalCode"
          flat
          rounded
          filled
          disabled
          prepend-icon="mdi-code-tags"
          hide-details="auto"
          @input="updateObject('internalCode', $event)"
        ></v-text-field>
      </v-col>
      <v-col
        cols="6"
        sm="2"
        md="2"
        lg="1"
        xl="1"
      >
        Progressivo
        <v-text-field
          v-model="code"
          flat
          filled
          disabled
          rounded
          hide-details="auto"
          @input="updateObject('code', $event)"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        md="3"
        lg="3"
        xl="3"
      >
        Numero Fattura o Documento
        <v-text-field
          :disabled="disabledForm"
          v-model="documentNumber"
          flat
          filled
          label="Numero documento"
          rounded
          hide-details="auto"
          @input="updateObject('documentNumber', $event)"
          :rules="[presenceRule]"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="8"
        md="4"
        lg="3"
        xl="3"
        v-if="status !== 'paid' && ['new'].includes(variant)"
      >
        <SuppliersAutocomplete
          class="ma-5 pr-5"
          v-model="supplierSelected"
          :clearable="type === 'warehouse'"
          return-object
          :error="!supplierSelected"
          @input="handleSupplierChange"
          prepend-icon="mdi-domain"
          :append-outer-icon="!!supplierSelected ? 'mdi-pencil' : undefined"
          :auto-detect-new-supplier="true"
        ></SuppliersAutocomplete>
      </v-col>
      <v-col
        cols="5"
        sm="4"
        md="3"
        lg="3"
        xl="3"
        v-if="['edit', 'detail'].includes(variant)"
      >
        <v-text-field
          class="ma-5"
          v-model="supplierNameToShow"
          v-if="invoiceHeadedToSelected === 'supplier'"
          :readonly=" (variant=='detail') && !editOptionByAdmin"
          flat
          filled
          label="Azienda"
          rounded
          hide-details="auto"
          @input="updateObject('lastname', $event)"
        ></v-text-field> 
      </v-col>
      <v-col cols="3" v-else-if="type === 'warehouse'">
        <DetailOrder
          class="border-default"
          max-height="45vh"
          max-width="18vw"
          min-width="18vw"
          min-height="45vh"
          v-if="orderSelected"
          :current-order-id="orderSelected ? Number(orderSelected.id) : 0"
        >
        </DetailOrder>
      </v-col>
      <v-col cols="3" v-else-if="type == 'cashDesk' && !!billSelected">
        <DetailBill
          class="border-default"
          max-height="45vh"
          max-width="18vw"
          min-width="18vw"
          min-height="45vh"
          v-if="billSelected"
          :current-bill-id="billSelected ? Number(billSelected.id) : 0"
        >
        </DetailBill>
      </v-col>  
      <v-col cols="auto">
        <v-switch
          color="red"
          v-model="ghost"
          @change="updateObject('ghost', $event)"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col 
            cols="6"
            sm="6"
            md="3"
            lg="3"
            xl="3"
          >
            Note / Descrizione Movimento
            <v-textarea
              :disabled="disabledForm"
              v-model="description"
              flat
              filled
              dense
              rounded
              hide-details="auto"
              @input="updateObject('description', $event)"
              :rules="[presenceRule]"
              rows="3"
            ></v-textarea>
          </v-col>
          <v-col
            cols="6"
            sm="6"
            md="3"
            lg="3"
            xl="3"
          >
            Documenti Collegati
            <v-text-field
              v-model="linkedDocuments"
              dense
              filled
              label="Documenti Collegati"
              hide-details="auto"
              rounded
              flat
              :readonly=" variant=='new' ? false : true"
              @input="updateObject('linkedDocuments', $event)"
            ></v-text-field>
          </v-col>  
          <v-col
            cols="6"
            sm="6"
            md="3"
            lg="3"
            xl="3"
          >
            {{ "Data Fattura" }}
            <div class="pr-5">
              <DatePickerCommon
                filled
                flat
                :styles="{
                  'pointer-events': ['new'].includes(variant) ? 'all' : 'none',
                  opacity: ['new'].includes(variant) ? '1' : '0.6',
                }"
                label="Data Fattura"
                v-model="emittedAt"
                @input="updateObject('emittedAt', $event)"
                :rules="[presenceRule]"
                :error="!emittedAt"
                :readonly=" (variant=='detail') && !editOptionByAdmin ? true : false"
              ></DatePickerCommon>
            </div>
          </v-col>
          <v-col 
            cols="6"
            sm="6"
            md="3"
            lg="3"
            xl="3"
          >
            Data Scadenza
            <div>
              <DatePickerCommon
                filled
                flat
                :styles="{
                  'pointer-events': ['new'].includes(variant) ? 'all' : 'none',
                  opacity: ['new'].includes(variant) ? '1' : '0.6',
                }"
                label="Data Scadenza"
                v-model="expirationDate"
                :rules="[presenceRule]"
                :error="!expirationDate"
                @input="updateObject('expirationDate', $event)"
                :readonly=" ((variant=='detail') && !editOptionByAdmin) ? true : false"
              ></DatePickerCommon>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="4"
          >
            Modalità Di Pagamento
            <v-select
              v-model="paymentTermId"
              :items="paymentTermsItems"
              item-value="id"
              item-text="description"
              label="Modalità Di Pagamento"
              filled
              :disabled="disabledForm"
              rounded
              flat
              dense
              :rules="[presenceRule]"
              hide-details="auto"
              :readonly="variant == 'detail'"
              prepend-icon="mdi-contactless-payment"
              @change="updateObject('paymentTermId', $event)"
            ></v-select>
          </v-col>
          <v-col 
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="4"
          >
            Tipo Natura Esenzione IVA
            <v-select
              v-model="paymentTypeNatureExemptionId"
              :items="paymentTypeNatureExemptionItems"
              item-value="id"
              item-text="description"
              :disabled="disabledForm"
              label="Tipo Natura Esenzione IVA"
              filled
              rounded
              flat
              dense
              hide-details="auto"
              :readonly="variant == 'detail'"
              prepend-icon="mdi-contactless-payment"
              @change="updateObject('paymentTypeNatureExemptionId', $event)"
            ></v-select>
          </v-col>
          <v-col 
            cols="12"
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            Operatore
            <OperatorsAutocomplete
              v-model="operatorId"
              :multiple="false"
              :rules="[presenceRule]"
              @input="updateObject('operatorId', $event)"
              :readonly=" (variant=='detail') && !editOptionByAdmin ? true : false"
              label="Operatore"
              rounded
              flat
              dense
            ></OperatorsAutocomplete>  
          </v-col> 
        </v-row>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col 
        cols="6"
        sm="4"
        md="2"
        lg="2"
        xl="2"
      >
        Aliquota
        <TaxesListSelector
          v-model="additionalVatPercentage[0]"
          :rules="[greaterThan0]"
          :readonly=" ((variant=='detail') && !editOptionByAdmin) || (!!invoice.status && invoice.status=='beingPaid')? true : false"
        ></TaxesListSelector>
      </v-col>
      <v-col 
        cols="6"
        sm="4"
        md="2"
        lg="2"
        xl="2"
      >
        Imponibile
        <v-text-field
          v-model="additionalTaxable[0]"
          dense
          filled
          label="Imponibile"
          hide-details="auto"
          :rules="[greaterThan0]"
          rounded
          flat
          type="number"
          :readonly=" ((variant=='detail') && !editOptionByAdmin) || (!!invoice.status && invoice.status=='beingPaid')? true : false"
          :input="updateAdditionalTaxable(0)"
        ></v-text-field>
      </v-col>
      <v-col 
        cols="6"
        sm="4"
        md="2"
        lg="2"
        xl="2"
      >
        Importo
        <v-text-field
          v-model="additionalAmount[0]"
          dense
          filled
          label="Importo"
          type="number"
          hide-details="auto"
          rounded
          flat
          readonly
        ></v-text-field>
        <!--
          :input="updateAdditionalAmount(0)"
          -->
      </v-col>
      <v-col 
        cols="6"
        sm="4"
        md="2"
        lg="2"
        xl="2"
      >
        Importo Iva
        <v-text-field
          v-model="additionalVatAmount[0]"
          dense
          filled
          label="Importo Iva"
          readonly
          rounded
          hide-details="auto"
          flat
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        md="4"
        lg="4"
        xl="4"
      >
        Tag
        <TagsAutocomplete
          v-model="additionalTag[0]"
          @input="updateObject('additionalTag', additionalTag)"
          dense
          return-object
          :rules="[presenceRule]"
          :readonly="((variant=='detail') && !editOptionByAdmin) || (!!invoice.status && invoice.status=='beingPaid')? true : false"
          :multiple="false"
          :filters="{typeCost:true}"
        ></TagsAutocomplete>  
      </v-col> 
    </v-row>
          <!-- Per più aliquote -->
      <v-row v-for="(n) in nTaxes" :key="n">
        <v-col 
          cols="6"
          sm="4"
          md="2"
          lg="2"
          xl="2"
        >
          Aliquota
          <TaxesListSelector
            v-model="additionalVatPercentage[n]"
            :rules="[greaterThan0]"
            :readonly=" ((variant=='detail') && !editOptionByAdmin) || (!!invoice.status && invoice.status=='beingPaid')? true : false"
          ></TaxesListSelector>
        </v-col>
        <v-col 
          cols="6"
          sm="4"
          md="2"
          lg="2"
          xl="2"
        >
          Imponibile
          <v-text-field
            v-model="additionalTaxable[n]"
            dense
            filled
            label="Imponibile"
            hide-details="auto"
            :rules="[greaterThan0]"
            rounded
            type="number"
            :input="updateAdditionalTaxable(n)"   
            :readonly="((variant=='detail') && !editOptionByAdmin) || (!!invoice.status && invoice.status=='beingPaid')? true : false"
          ></v-text-field>
        </v-col>
        <v-col 
          cols="6"
          sm="4"
          md="2"
          lg="2"
          xl="2"
        >
          Importo
          <v-text-field
            v-model="additionalAmount[n]"
            :rules="[greaterThan0]"
            dense
            filled
            label="Importo"
            type="number"
            hide-details="auto"
            rounded
            readonly
          ></v-text-field>
          <!--
            :input="updateAdditionalAmount(n)"
          -->
        </v-col>
        <v-col
          cols="6"
          sm="4"
          md="2"
          lg="2"
          xl="2"
        >
          Importo Iva
          <v-text-field
            v-model="additionalVatAmount[n]"
            dense
            filled
            label="Importo Iva"
            readonly
            hide-details="auto"
            rounded
          ></v-text-field>
        </v-col>
        <v-col 
          cols="12"
          sm="4"
          md="4"
          lg="4"
          xl="4"
        >
          Tag
          <TagsAutocomplete
            v-model="additionalTag[n]"
            @input="updateObject('additionalTag', additionalTag)"
            dense
            return-object
            :rules="[presenceRule]"
            :error="!additionalTag[n]"
            :readonly="((variant=='detail') && !editOptionByAdmin) || (!!invoice.status && invoice.status=='beingPaid')? true : false"
            :multiple="false"
            :filters="{typeCost:true}"
          ></TagsAutocomplete>  
        </v-col> 
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center mb-2">
          <v-btn color="primary" outlined class="px-10 mr-2" @click="addRow()" :disabled="((variant=='detail') && !editOptionByAdmin) || (!!invoice.status && invoice.status=='beingPaid')? true : false">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn color="error" outlined class="px-10 ml-2" @click="removeRow()" :disabled="nTaxes < 1 || ((variant=='detail') && !editOptionByAdmin) || (!!invoice.status && invoice.status=='beingPaid')? true : false">
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
  </v-form>
  
</template>

<script>
import Vue from "vue";
import invoiceForm from "@/services/invoices/invoice.form.js";
import { DateTimePicker } from "likablehair-ui-components";
import AnimatedCheck from "@/components/common/AnimatedCheck.vue";

import CustomersAutocomplete from "@/components/common/CustomersAutocomplete.vue";
import TagsAutocomplete from "@/components/common/TagsAutocomplete.vue";
import OperatorsAutocomplete from "@/components/common/OperatorsAutocomplete.vue";
import SuppliersAutocomplete from "@/components/common/SuppliersAutocomplete.vue";
import RadioGroupComponent from "@/components/common/RadioGroupComponent.vue";
import DetailOrder from "@/components/areas/payments/invoices/components/DetailOrder.vue";
import DetailBill from "@/components/areas/payments/invoices/components/DetailBill.vue";
import TaxesListSelector from "@/components/areas/payments/taxes/TaxesListSelector.vue"

import invoiceService from "@/services/invoices/invoices.service.js";
import dataInvoiceService from "@/services/invoices/dataInvoices.service.js";
import paymentTermService from "@/services/paymentTerms/paymentTerms.service.js";
import paymentConditionService from "@/services/paymentTerms/paymentConditions.service.js";
import paymentTypeNatureExemptionService from "@/services/paymentTerms/paymentTypeNatureExemptions.service.js";
import DatePickerCommon from '@/components/common/DatePickerCommon.vue'
import StandardDialog from "@/components/common/StandardDialog.vue"



export default {
  name: "GeneralFormTab",
  components: {
    DateTimePicker,
    CustomersAutocomplete,
    RadioGroupComponent,
    SuppliersAutocomplete,
    DetailOrder,
    DetailBill,
    AnimatedCheck,
    DatePickerCommon,
    TaxesListSelector,
    TagsAutocomplete,
    OperatorsAutocomplete,
    StandardDialog
  },
  data: function () {
    return {
      amount: undefined,
      vatAmount: undefined,
      taxable: undefined,
      type: undefined,
      oldType: undefined,
      emittedAt: null,
      status: "",
      ghost: undefined,
      lastname: undefined,
      description: undefined,
      firstname: undefined,
      vatPercentage: undefined,
      id: undefined,
      code: 0,
      internalCode: "",
      expirationDate: null,
      documentNumber: undefined,
      operatorId: null,
      supplierId: undefined,
      linkedDocuments: undefined,
      supplierDataErrorDialog: false,
      supplierDataErrorMessage: "Assicurarsi che l'Azienda selezionata abbia i campi: Partita IVA, Indirizzo, Codice Destinatario, Mail e Nome Salvati!",

      invoiceTypes: invoiceService.listType(),
      loadingInvoice: false,
      formValid: false,
      typeCashDesk: "cashDesk",
      supplierSelected: null,
      customerSelected: null,
      suppliers: [],
      customers: [],
      orderSelected: [],
      billSelected: [],
      invoiceHeadedToSelected: "",
      invoiceTypeSelected: "",
      disabledForm: false,
      invoicePaid: false,
      invoicePaidMessage: "",
      paymentTypeNatureExemptionId: undefined,
      paymentTypeNatureExemptionItems: [],
      paymentTermId: undefined,
      paymentTermsItems: [],
      tags: [],
      supplierNameToShow: undefined,
      nTaxes: 0,
      additionalVatPercentage: [undefined],
      additionalVatAmount: [undefined],
      additionalTaxable: [undefined],
      additionalAmount: [undefined],
      additionalTag: [undefined],
      max255CharRule: (v) => {
        if (v) {
          return v.length <= 255 || "Al massimo 255 caratteri";
        } else {
          return true;
        }
      },
      presenceRule: (v) => {
        return  !!v || "Il campo è obbligatorio";
      },
      greaterThan0: (v) => {
        return (!isNaN(v) && v >= 0 ) || "Importo non valido";
      }
    };
  },
  props: {
    invoice: {
      type: Object,
      default: () => {
        return {};
      }
    },
    bus: {
      type: Object,
      default: () => {
        return new Vue();
      }
    },
    variant: {
      type: String,
      default: "new",
      validator: function (value) {
        if (!["new", "edit", "detail"].includes(value)) {
          return "invalid variant";
        } else {
          return true;
        }
      },
    },
    editOptionByAdmin: {
      type: Boolean,
      default: false
    },
  },
  mounted: function () {
    var self = this;
    invoiceForm.on("update", function (data) {
      self.handleObjectChanges(data.invoice);
    });
    this.lastInternalCode();
    this.handleInvoiceHeadedToSelected("supplier");
    this.fetchPaymentTerms();

    this.handleInvoiceTypeSelected();
    this.updateObject('type','otherToPay')
    this.lastCode('otherToPay')
    if (this.variant === "detail") {
      this.disabledForm = true;
    }

    this.$refs.invoiceToPayForm.validate()
  },
  methods: {
    fetchPaymentTerms() {
      new Promise(async (resolve, reject) => {
        let resultPaymentTermsItems = await paymentTermService.list();
        this.paymentTermsItems = [...resultPaymentTermsItems.rows];

        let resultPaymentConditionItems = await paymentConditionService.list();
        this.paymentConditionItems = [...resultPaymentConditionItems.rows];

        let resultPaymentTypeNatureExemptionItems =
          await paymentTypeNatureExemptionService.list();
        this.paymentTypeNatureExemptionItems = [
          ...resultPaymentTypeNatureExemptionItems.rows,
        ];

        this.docPrintItems = dataInvoiceService.listDocPrint();
        resolve();
      });
    },
    showCheckPaid() {
      this.invoicePaid = this.status === "paid" ? true : false;
      this.invoicePaidMessage = "Fattura pagata correttamente"
    },
    lastInternalCode() {
      invoiceService.getLastInvoice().then((lastInvoice) => {
        let progressiveInCode;
        let today = new Date();
        if (
          !!lastInvoice &&
          Number(lastInvoice.createdAt.substring(0, 4)) == today.getFullYear()
        ) {
          progressiveInCode = (Number(lastInvoice.progressive) + 1).toString();
          while (progressiveInCode.length < 5) {
            progressiveInCode = "0" + progressiveInCode;
          }
        } else {
          progressiveInCode = "00001";
        }
        this.internalCode =
          "FAT" + today.getFullYear() + "-" + progressiveInCode;
      });
    },
    lastCode(type) {
      invoiceService.getLastCode(type).then((lastCode) => {
        if (!!lastCode && this.variant == 'new')
          this.code = !!lastCode ? Number(lastCode) + 1 : Number(lastCode);
        else if(!!lastCode && this.variant != 'new')
          this.code = lastCode
        else 
          this.code = 1;
        this.updateObject("code", this.code);
      });
    },
    handleInvoiceHeadedToSelected(value) {
      this.invoiceHeadedToSelected = value;
    },
    handleInvoiceTypeSelected(value) {
      this.lastCode(value);
      this.invoiceTypeSelected = value;
      this.updateObject("type", value);
    },
    handleSupplierChange(supplierSelected) {
      if(!!supplierSelected){
        if(!supplierSelected.id || !supplierSelected.VATNumber || !supplierSelected.address || !supplierSelected.businessName || !supplierSelected.email || !supplierSelected.dataInvoice.destinataryCode){
          this.$nextTick(() => {
            this.supplierSelected = undefined
            this.supplierDataErrorDialog = true
          })
        }
        else{
          invoiceForm.updateFields(['supplierId', 'vatNumber', 'address', 'lastname', 'mail', 'destinataryCode'], 
          [supplierSelected.id, supplierSelected.VATNumber, supplierSelected.address, supplierSelected.businessName, supplierSelected.email, supplierSelected.dataInvoice.destinataryCode])
          this.bus.$emit("change-suppplier", supplierSelected)
        }
      }
    },
    fields() {
      return [
        "lastname",
        "description",
        "amount",
        "type",
        "firstname",
        "vatPercentage",
        "internalCode",
        "code",
        "status",
        "id",
        "operatorId",
        "taxable",
        "vatAmount",
        "documentNumber",
        "tags",
        "linkedDocuments",
        "additionalVatPercentage",
        "additionalVatAmount",
        "additionalTaxable",
        "additionalAmount",
        "additionalTag",  
        "ghost"
      ];
    },
    handleObjectChanges(invoice) {
      const fields = this.fields();
      const newValKeys = Object.keys(invoice);

      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];

        if (newValKeys.includes(field) && invoice[field] != this[field]) {
          this[field] = invoice[field];
        }
      }
      if (!!invoice.emittedAt && !this.emittedAt) {
        this.emittedAt = new Date(invoice.emittedAt);
      }
      if (!!invoice.expirationDate && !this.expirationDate) {
        this.expirationDate = new Date(invoice.expirationDate);
      }

      if (!!invoice.paymentTermId && !this.paymentTermId) {
        this.paymentTermId = Number(invoice.paymentTermId);
      }
      if (!!invoice.paymentTypeNatureExemptionId && !this.paymentTypeNatureExemptionId) {
        this.paymentTypeNatureExemptionId = Number(invoice.paymentTypeNatureExemptionId);
      }
      if(!!invoice.lastname && !this.supplierSelected){
        this.supplierNameToShow = invoice.lastname
      }
      if(!!invoice.additionalVatPercentage && !this.additionalVatPercentage){
        this.additionalVatPercentage = invoice.additionalVatPercentage
      }
      if(!!invoice.additionalVatAmount && !this.additionalVatAmount){
        this.additionalVatAmount = invoice.additionalVatAmount
      }
      if(!!invoice.additionalTaxable && !this.additionalTaxable){
        this.additionalTaxable = invoice.additionalTaxable
      }
      if(!!invoice.additionalAmount && !this.additionalAmount){
        this.additionalAmount = invoice.additionalAmount
      }
      if(!!invoice.invoiceAmounts && !!invoice.invoiceAmounts.length && this.additionalVatPercentage[0] === undefined){
        this.nTaxes = invoice.invoiceAmounts.length - 1
        this.additionalVatPercentage[0] = Number(invoice.invoiceAmounts[0].vatPercentage)
        this.additionalVatAmount[0] = Number(invoice.invoiceAmounts[0].vatAmount)
        this.additionalTaxable[0] = Number(invoice.invoiceAmounts[0].taxable)
        this.additionalAmount[0] = Number(invoice.invoiceAmounts[0].amount)
        this.additionalTag[0] = invoice.invoiceAmounts[0].tags
        this.additionalTag[0][0].description = this.additionalTag[0][0].description + " - " + this.additionalTag[0][0].tagCategoryName
        for(let i = 1; i < invoice.invoiceAmounts.length; i++){   
          this.additionalVatPercentage[i] = Number(invoice.invoiceAmounts[i].vatPercentage)
          this.additionalAmount[i] = Number(invoice.invoiceAmounts[i].amount)
          this.additionalVatAmount[i] = Number(invoice.invoiceAmounts[i].vatAmount)
          this.additionalTaxable[i] = Number(invoice.invoiceAmounts[i].taxable)
          this.additionalTag[i] = invoice.invoiceAmounts[i].tags
          this.additionalTag[i][0].description = this.additionalTag[i][0].description + " - " + this.additionalTag[i][0].tagCategoryName
        }
        //delete invoice.invoiceAmounts
      }

      this.showCheckPaid();
    },
    updateObject(key, value) {
      if (key == "additionalTag") {
        for (let i = 0; i < value.length; i++) {
          this.additionalTag[i][0].description = this.additionalTag[i][0].description + " - " + this.additionalTag[i][0].categoryObject.name
        }
      }
      if (key == "type" && !this.oldType) {
        this.oldType = value;
      }
      invoiceForm.updateField(key, value);
    },
    addRow(){
      this.nTaxes++;

      this.additionalVatPercentage.push(undefined)
      this.additionalVatAmount.push(undefined)
      this.additionalTaxable.push(undefined)
      this.additionalAmount.push(undefined)
      this.additionalTag.push(undefined)
    },
    removeRow(){
      this.additionalVatPercentage.pop()
      this.additionalVatAmount.pop()
      this.additionalTaxable.pop()
      this.additionalAmount.pop()
      this.additionalTag.pop()

      invoiceForm.updateFields(['additionalVatPercentage','additionalTaxable','additionalVatAmount','additionalAmount', 'additionalTag'], [this.additionalVatPercentage, this.additionalTaxable, this.additionalVatAmount, this.additionalAmount, this.additionalTag])
      this.nTaxes--;
    },
    updateAdditionalAmount(index){
      let percentage = this.additionalVatPercentage[index]
      if(String(percentage).length == 1)
        percentage = "0" + String(percentage)

      if(!!this.additionalAmount[index] && this.additionalVatPercentage[index] != undefined){
        this.additionalTaxable[index] = (Number(this.additionalAmount[index]) / Number('1.' + String(percentage))).toFixed(2)
        this.additionalVatAmount[index] = Number(Number(this.additionalAmount[index]) - this.additionalTaxable[index]).toFixed(2)
        invoiceForm.updateFields(['additionalVatPercentage','additionalTaxable','additionalVatAmount','additionalAmount', 'additionalTag'], [this.additionalVatPercentage, this.additionalTaxable, this.additionalVatAmount, this.additionalAmount, this.additionalTag])
      }
    },
    updateAdditionalTaxable(index){   
      let percentage = this.additionalVatPercentage[index]
      if(String(percentage).length == 1)
        percentage = "0" + String(percentage)

      if(!!this.additionalTaxable[index] && this.additionalVatPercentage[index] != undefined){
        this.additionalAmount[index] = (Number(this.additionalTaxable[index]) * Number('1.' + String(percentage))).toFixed(2)
        this.additionalVatAmount[index] = Number(Number(this.additionalAmount[index]) - this.additionalTaxable[index]).toFixed(2)
        invoiceForm.updateFields(['additionalVatPercentage','additionalTaxable','additionalVatAmount','additionalAmount', 'additionalTag'], [this.additionalVatPercentage, this.additionalTaxable, this.additionalVatAmount, this.additionalAmount, this.additionalTag])
      }
    }
  },
  watch: {
    $attrs(newVal) {
      if (!!newVal.invoice) {
        if (
          !!newVal.invoice.invoiceOrder &&
          !!newVal.invoice.invoiceOrder.order
        )
          this.orderSelected = newVal.invoice.invoiceOrder.order[0];
        else if (!!newVal.invoice.bills && !!newVal.invoice.bills[0])
          this.billSelected = newVal.invoice.bills[0];
        if (
          !!newVal.invoice.invoiceSupplier &&
          !!newVal.invoice.invoiceSupplier.supplier
        )
          this.handleInvoiceHeadedToSelected("supplier");
        else if (
          !!newVal.invoice.invoiceCustomer &&
          !!newVal.invoice.invoiceCustomer.customer
        )
          this.handleInvoiceHeadedToSelected("customer");
        if (!["warehouse", "cashDesk"].includes(newVal.invoice.type))
          this.handleInvoiceTypeSelected(newVal.invoice.type);
        else {
          this.code = newVal.invoice.code;
        }
      }
    },
    formValid(newVal) {
      this.$emit("update:valid", newVal);
      if (this.bus) {
        this.bus.$emit("update:valid", newVal);
      }
      invoiceForm.setValid(newVal);
    },
  },
};
</script>
<style scoped>
.border-default {
  padding: 5px;
  border-radius: 25px;
  border: 1px solid #dcd8d8;
  transition: all 0.2s;
  transform: scale(1);
}
</style>